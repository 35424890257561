<template lang="pug">
  .row
    .col-md-6
      .component-card(style="max-width: 300px")
        v-color-select(
          reset-color
          v-model="color")
    .col-md-6
</template>

<script>
import VColorSelect from '@/components/ui/v-color-select/VColorSelect'

export default {
  name: 'VImageSelectDemo',

  components: {
    VColorSelect
  },

  data: () => ({
    color: ''
  })
}
</script>

<style>
</style>
